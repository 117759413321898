<template>
  <div class="modal">
    <v-row>
      <v-col class="col-12 pt-0 pb-2">
        <h1>
          Purchase order - add document
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-12 col-md-10">
        <div class="uploads"></div>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-12">
        <v-btn @click="$emit('close')" small>Close</v-btn>
      </v-col>
    </v-row>
  </div>
</template>
  
  <script>
  import axios from '../../../axios';
  
  import Uppy from '@uppy/core';
  import Dashboard from '@uppy/dashboard';
  import Transloadit from '@uppy/transloadit'
  import '@uppy/core/dist/style.css'
  import '@uppy/dashboard/dist/style.css'
  
  export default {
    name: 'AddDocuments',
    props: [
      'poId',
    ],
    computed: {
      token() {
        return this.$store.state.token;
      },
      userid() {
        return this.$store.state.userid;
      },
    },
    mounted() {
      const uppy = new Uppy({
        restrictions: {
          maxNumberOfFiles: 1,
          minNumberOfFiles: 1,
        },
      });
      uppy.use(Dashboard, {
        inline: true,
        width: '100%',
        target: '.uploads',
        proudlyDisplayPoweredByUppy: false,
      })
      .use(Transloadit, {
        service: 'https://api2.transloadit.com',
        params: {
          auth: { key: 'db6a1d40944f11e5a0a165fb32f6e49b' },
          template_id: 'f73197cf10564cdf814d34642589e166',
        },
        waitForEncoding: true,
        waitForMetadata: false,
        importFromUploadURLs: false,
        alwaysRunAssembly: false,
        signature: null,
        fields: {},
        limit: 0,
      })
      .on('complete', (results) => {
        const postData = {};
        postData.results = results;
        axios.post(`/purchaseOrderDocuments/addDocument/${this.poId}.json?token=${this.token}`, postData)
          .then((response) => {
            this.$emit('documentAdded', response.data.document);
            uppy.reset();
          });
      });
    },
  };
  </script>
  
  <style lang="scss">
  .uppy-Dashboard-inner {
    max-height: 350px!important;
  }
  </style>
  