<template>
  <div class="modal">
    <v-row>
      <v-col class="col-6">
        <v-text-field
          clearable
          @keyup="lookUpStockDebounced"
          label="Search for a stock item"
          v-model="searchTerm"></v-text-field>
      </v-col>
      <v-col class="col-6 text-right"><v-btn @click="$emit('close')" small>Close</v-btn></v-col>
    </v-row>
    <v-row dense v-if="foundStocks.length > 0">
      <v-col class="col-12">
        <v-list>
          <v-list-item
            @click="populateStock(index)"
            v-for="(stock, index) in foundStocks" :key="`s_${index}`">
              {{ stock.text }}
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </div>  
</template>
  
<script>
import axios from '../../../axios';

export default {
  name: 'PoAddFromStock',
  data() {
    return {
      foundStocks: [],
      searchTerm: null,
    };
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  methods: {
    lookUpStockDebounced() {
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        this.lookUpStock();
      }, 500);
    },
    lookUpStock() {
      if (this.searchTerm) {
        if (this.searchTerm.length > 2) {
          const postData = { searchTerm: this.searchTerm };
          axios.post(`/pal/lookUpStock.json?token=${this.token}`, postData)
          .then((response) => {
            this.foundStocks = response.data.stock;
          });
        }
      }
    },
    populateStock(stockIndex) {
      const stock = this.foundStocks[stockIndex];
      this.$emit('populateStock', stock);
    },
  },
  mounted() {},
}
</script>
