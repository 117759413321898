<template>
  <div class="modal">
    <v-row>
      <v-col class="col-4">
        <h4>Add a one off item</h4>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-4">
        <v-text-field
          label="Description"
          v-model="item.text"></v-text-field>
      </v-col>
      <v-col class="col-2">
        <v-text-field
          type="number"
          label="Quantity"
          v-model="item.quantity"></v-text-field>
      </v-col>
      <v-col class="col-2">
        <v-text-field
          type="number"
          step="0.01"
          label="Price"
          v-model="item.price"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-4">
        <v-btn @click="addItem()" small>Add</v-btn>
        &nbsp;
        <v-btn @click="$emit('close')" small>Close</v-btn>
      </v-col>
    </v-row>
  </div>  
</template>
  
<script>
export default {
  name: 'PoAddNewItem',
  data() {
    return {
      item: {
        text: '',
        price: 0.00,
        quantity: 0,
      },
    };
  },
  methods: {
    addItem() {
      this.$emit('addItem', this.item);
    },
  },
}
</script>
