<template>
    <v-container fluid>
      <v-row dense>
        <v-col class="col-4">
          <h1 v-if="po.authorised_by > 0">View po #{{ po.id }}</h1>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col class="col-4">
          <v-btn @click="addDocument = true" small>Add Document</v-btn>
          &nbsp;
          <v-btn @click="cancel" small>Cancel</v-btn>
          &nbsp;
          <v-btn v-if="po.authorised_by > 0" @click="markComplete" small>Mark Complete</v-btn>
          &nbsp;
          <router-link v-if="po.authorised_by > 0" :to="`/purchasing/purchase-orders-print-view/${po.id}`">
            <v-btn small>Print</v-btn>
          </router-link>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col class="col-6">
          <p class="mb-0">Requisition date: {{ po.date_of_requisition | tinyDate }}</p>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col class="col-6">
          <h4>Supplier</h4>
          <p v-if="po.supplier.name">{{ po.supplier.name }}</p>
          <h4>Supplier address</h4>
          <span v-if="po.supplier.address">{{ supplier.address }}</span>
          <h4 v-if="po.supplier.telephone" class="pt-4 pb-0">Supplier telephone</h4>
          <span v-if="po.supplier.telephone">{{ po.supplier.telephone }}</span>
          <h4 v-if="po.supplier.email" class="pt-4 pb-0">Supplier email</h4>
          <span v-if="po.supplier.email">{{ po.supplier.email }}</span>
          <h4 class="pt-4 pb-0" v-if="po.supplier.main_contact">Contact</h4>
          <p v-if="po.supplier.main_contact">{{ po.supplier.main_contact }}</p>
          <h4 class="pt-4 pb-0" v-if="po.supplier.type_of_supply">Type of Supply</h4>
          <p v-if="po.supplier.type_of_supply">{{ po.supplier.type_of_supply }}</p>
        </v-col>
        <v-col class="col-6 pb-10">
          <label>Delivery Address</label>
          <v-text-field v-model="po.delivery_address"></v-text-field>
          <label>Notes</label>
          <vue-editor
              id="notes"
              :editor-toolbar="customToolbar"
              v-model="po.notes" />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-4">
          <v-text-field v-model="po.for_the_attention_of" label="F.T.A.O"></v-text-field>
        </v-col>
        <v-col class="col-4">
          <v-text-field v-model="po.quotation_reference" label="Quotation reference"></v-text-field>
        </v-col>
        <v-col class="col-4">
          <v-text-field v-model="po.sub_contractor_reference" label="Subcontractor reference"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-4">
          <v-menu
            v-model="datePicker"
            :close-on-content-click="true"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="po.delivery_date"
                label="Delivery date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                suffix="*"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="deliveryDate"
              @input="menu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col class="col-4">
          <v-menu
            v-model="datePickerTwo"
            :close-on-content-click="true"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="po.contract_date"
                label="Contract date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                suffix="*"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="contractDate"
              @input="menu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row v-if="po.purchase_order_documents.length > 0">
        <v-col>
          <v-list dense>
            <v-list-item
              :key="`pod_${index}`"
              v-for="(document, index) in po.purchase_order_documents">
              <v-row>
                <v-col class="col-10">
                  <a :href="`https://riggotts.s3.eu-west-2.amazonaws.com/original/${document.uri}`" target="_blank">
                    <v-btn>View document - {{ document.uri }}</v-btn>
                  </a>
                </v-col>
                <v-col class="text-right col-2">
                  <v-btn @click="removeDocument(index)">Remove Document</v-btn>
                </v-col>
              </v-row>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
      <v-row class="box-row">
        <v-col class="col-12">
          <v-simple-table>
            <thead>
              <tr>
                <th>Description</th>
                <th>Quantity</th>
                <th>Price</th>
                <th>Value</th>
                <th v-if="po.authorised_by > 0">Received</th>
                <th v-if="po.authorised_by > 0">Date Received</th>
                <th v-if="po.authorised_by > 0">Receive</th>
                <th v-if="po.authorised_by > 0">&nbsp;</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(poLine, index) in po.purchase_order_lines" :key="`pol_${index}`">
                <td>{{ poLine.text }}</td>
                <td>
                  <v-text-field
                    v-if="po.authorised_by === 0"
                    @keyup="updateTotal(index)"
                    v-model="poLine.quantity"
                    type="number"></v-text-field>
                  <span v-else>{{ poLine.quantity }}</span>
                </td>
                <td>
                  <v-text-field
                    v-if="po.authorised_by === 0"
                    @keyup="updateTotal(index)"
                    v-model="poLine.price"
                    step="0.01"
                    type="number"></v-text-field>
                  <span v-else>
                    {{ poLine.price | currency }}
                  </span>
                </td>
                <td>{{ (poLine.price * poLine.quantity) | currency }}</td>
                <td v-if="po.authorised_by > 0">{{ poLine.quantity_received }}</td>
                <td v-if="po.authorised_by > 0">
                  <span v-for="(receipt, rindex) in poLine.purchase_order_receipts" :key="`rec_${rindex}`">
                    <span class="link" @click="activateReceiptDatePicker(receipt.id, index, rindex)">
                      {{ receipt.quantity }} on {{ receipt.date | tinyDate }}
                    </span>
                    <v-icon @click="deleteReceived(index, rindex)" color="red" small>mdi-delete</v-icon>
                    <br />
                  </span>
                </td>
                <td v-if="po.authorised_by > 0">
                  <v-text-field v-model="poLine.newly_received" type="number" step="0.25"></v-text-field>
                </td>
                <td v-if="po.authorised_by > 0">
                  <v-btn x-small @click="updateReceived(index)">Save</v-btn>
                </td>
                <td><v-btn v-if="po.authorised_by === 0" @click="deleteLine(index)" x-small color="red">Remove</v-btn></td>
              </tr>
            </tbody>
          </v-simple-table>
          <p v-if="this.po.authorised_by > 0" class="text-right">
            <i>If you need to update the lines please click below - the PO will then need to be re-authorised</i><br />
            <v-btn small class="mt-3" @click="unauthorise">Update Lines</v-btn>
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col4">
          <v-btn v-if="po.authorised_by === 0" @click="addLineFromStock = true" block>Look up stock item</v-btn>
        </v-col>
        <v-col class="col4">
          <v-btn v-if="po.authorised_by === 0" @click="addNewItem = true" block>Add one off item</v-btn>
        </v-col>
        <v-col class="col4">
          <v-text-field @keyup="updateOrderValue" label="Carriage charges" v-model="po.carriage_charges"></v-text-field>
          <v-text-field label="Order value" v-model="po.order_value" disabled></v-text-field>
          <v-btn block @click="save">Save Changes</v-btn>
          <div v-if="po.authorised_by === 0">
            <v-btn v-if="showAuthorise" class="mt-4" block @click="authorise">Authorise</v-btn>
            <span v-else>Not authorised yet</span>
          </div>
          <div v-else>
            <p class="pt-4">Authorised by {{ po.authoriser.name }} on {{ po.date_of_authorisation | tinyDate }}</p>
          </div>
        </v-col>
      </v-row>
      <v-dialog
        v-model="receiptDatePicker"
        max-width="290"
      >
        <v-date-picker
          v-model="updatedReceiptDate">
        </v-date-picker>
      </v-dialog>
      <v-dialog
        v-model="showMessage"
        max-width="290"
      >
        <v-card>
          <v-card-text class="pt-4">
            {{ message }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="orange darken-2"
              text
              @click="showMessage = false"
            >
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <AddDocument
        @close="addDocument = false"
        @documentAdded="addNewDocument"
        :poId="po.id"
        v-if="addDocument === true" />
      <AddFromStock
        @close="addLineFromStock = false"
        @populateStock="addNewLineFromStock"
        v-if="addLineFromStock === true" />
      <AddNewItem
        @close="addNewItem = false"
        @addItem="addNewItemLine"
        v-if="addNewItem === true" />
    </v-container>
  </template>
  
  <script>
  import AddDocument from './components/AddDocument.vue';
  import AddFromStock from './components/AddFromStock.vue';
  import AddNewItem from './components/AddNewItem.vue';

  import { VueEditor } from "vue2-editor";
  import axios from '../../axios';
  
  export default {
    name: 'PoView',
    components: {
      AddDocument,
      AddFromStock,
      AddNewItem,
      VueEditor,
    },
    data() {
      return {
        addDocument: false,
        addLineFromStock: false,
        addNewItem: false,
        authorisers: [],
        customToolbar: [
          ["bold", "italic", "underline"],
        ],
        deliveryDate: null,
        contractDate: null,
        datePicker: false,
        datePickerTwo: false,
        foundSuppliers: [],
        message: '',
        po: {
          authorised: 0,
          authorised_by: 0,
          carriage_charges: 0,
          contract_date: null,
          date_of_authorisation: null,
          date_of_requisition: null,
          delivery_address: 'Riggott and Co Ltd, Unit X, Tuxford, Newark, Notts, NG22 9NU',
          delivery_date: null,
          for_the_attention_of: '',
          order_value: 0,
          purchase_order_lines: [],
          supplier: {
            name: '',
          },
        },
        polineIndex: -1,
        receiptDatePicker: false,
        receiptDateInUpdate: 0,
        receiptIndex: -1,
        updatedReceiptDate: null,
        searchTerm: null,
        showMessage: false,
        supplier: {},
      };
    },
    computed: {
      token() {
        return this.$store.state.token;
      },
      userid() {
        return this.$store.state.userid;
      },
      showAuthorise() {
        let showAuth = false;
        for (let i = 0; i < this.authorisers.length; i += 1) {
          if (this.authorisers[i].user_id === this.userid) showAuth = true;
        }
        return showAuth;
      },
    },
    watch: {
      deliveryDate () {
        this.po.delivery_date = this.formatDate(this.deliveryDate);
      },
      contractDate () {
        this.po.contract_date = this.formatDate(this.contractDate);
      },
      updatedReceiptDate () {
        this.updateTheReceiptDate();
      },
    },
    methods: {
      activateReceiptDatePicker(receiptId, index, rindex) {
        this.updatedReceiptDate = this.po.purchase_order_lines[index].purchase_order_receipts[rindex].date;
        this.receiptIndex = rindex;
        this.polineIndex = index;
        this.receiptDateInUpdate = receiptId;
        setTimeout(()=> {
          this.receiptDatePicker = true;
        }, 100);
      },
      updateTheReceiptDate() {
        const postData = {};
        postData.receipt = this.receiptDateInUpdate;
        postData.date = this.updatedReceiptDate;
        axios.post(`/purchaseOrderReceipts/changeDate.json?token=${this.token}`, postData)
          .then((response) => {
            const polineIndex = this.polineIndex;
            const receiptIndex = this.receiptIndex;
            this.po.purchase_order_lines[polineIndex].purchase_order_receipts[receiptIndex].date = response.data.date;
            this.receiptDatePicker = false;
            // this.receiptDateInUpdate = 0;    
          });
      },
      checkAllReceived() {
        axios.get(`/purchaseOrders/markAllReceived.json?token=${this.token}`);
      },
      unauthorise() {
        axios.get(`/purchaseOrders/unauthorise/${this.po.id}/${this.userid}.json?token=${this.token}`)
          .then(() => {
            this.po.authorised_by = 0;
          });
      },
      authorise() {
        const postData = {};
        postData.po = this.po;
        postData.po.authorised_by = this.userid;
        axios.post(`/purchaseOrders/authorise.json?token=${this.token}`, postData)
          .then((response) => {
            this.po.authorised_by = response.data.purchaseOrder.authorised_by;
            this.po.authoriser = response.data.purchaseOrder.authoriser;
            this.po.date_of_authorisation = response.data.purchaseOrder.date_of_authorisation;
          });
      },
      updateTotal(lineIndex) {
        clearTimeout(this._timerId);
        this._timerId = setTimeout(() => {
          this.updateTotalDebounced(lineIndex);
        }, 400);
      },
      updateTotalDebounced(lineIndex) {
        const line = this.po.purchase_order_lines[lineIndex];
        line.totalPrice = (line.price * line.quantity);
        this.po.purchase_order_lines.splice(lineIndex, 1, line);
        const postData = {};
        postData.line = line;
        axios.post(`/purchaseOrderLines/updateLine.json?token=${this.token}`, postData)
          .then(() => {
            this.updateOrderValue();
          });
      },
      updateOrderValue() {
        let total = 0;
        for(let i = 0; this.po.purchase_order_lines.length > i; i += 1) {
          total += (this.po.purchase_order_lines[i].quantity * this.po.purchase_order_lines[i].price);
        }
        if (this.po.carriage_charges) {
          total += parseInt(this.po.carriage_charges, 10);
        }
        this.po.order_value = total;
      },
      addNewItemLine(value) {
        this.addNewItem = false;
        const line = value;
        line.totalPrice = (line.quantity * line.price);
        line.quantity_received = 0;
        this.po.purchase_order_lines.push(line);
        this.updateOrderValue();
        const postData = {};
        postData.line = line;
        postData.purchaseOrderId = this.po.id;
        axios.post(`/purchaseOrderLines/add.json?token=${this.token}`, postData);
      },
      addNewLineFromStock(value) {
        this.addLineFromStock = false;
        const line = value;
        line.quantity = 1;
        line.price = parseFloat(line.price);
        const postData = {};
        postData.line = line;
        postData.purchaseOrderId = this.po.id;
        axios.post(`/purchaseOrderLines/add.json?token=${this.token}`, postData)
        .then((response) => {
          const line = response.data.line;
          line.quantity_received = 0;
          line.totalPrice = parseFloat(response.data.line.price);
          line.text = response.data.line.description;
          this.po.purchase_order_lines.push(line);
          this.updateOrderValue();
        });
      },
      addNewDocument(value) {
        this.addDocument = false;
        this.po.purchase_order_documents.push(value);
      },
      removeDocument(index) {
        const document = this.po.purchase_order_documents[index];
        axios.post(`/purchaseOrdersPurchaseOrderDocuments/remove/${this.po.id}/${document.id}.json?token=${this.token}`)
          .then(() => {
            this.po.purchase_order_documents.splice(index, 1);
          });
      },
      formatDate (date) {
        if (!date) return null;
        const [year, month, day] = date.split('-');
        return `${day}-${month}-${year}`;
      },
      save() {
        const postData = {};
        postData.po = this.po;
        axios.post(`/purchaseOrders/update.json?token=${this.token}`, postData)
          .then(() => {
            this.message = 'Your changes have been saved.';
            this.showMessage = true;
          });
      },
      cancel() {
        axios.get(`/purchaseOrders/cancel/${this.po.id}/${this.userid}.json?token=${this.token}`)
          .then(() => {
            this.$router.push('/purchasing/index');
          });
      },
      markComplete() {
        axios.get(`/purchaseOrders/markComplete/${this.po.id}/${this.userid}.json?token=${this.token}`)
          .then(() => {
            this.$router.push('/purchasing/index');
          });
      },
      getAuthorisers() {
        axios.get(`/authorisers/getAll.json?token=${this.token}`)
          .then((response) => { 
            this.authorisers = response.data.poAuthorisers;
          })
          .catch((error) => {
            console.log(error);
          });
      },
      getPurchaseOrder() {
        const poId = this.$route.params.id;
        axios.get(`/purchaseOrders/get/${poId}.json?token=${this.token}`)
          .then((response) => {
            this.po = response.data.purchaseOrder;
          })
          .catch((error) => {
            console.log(error);
          });
      },
      updateReceived(lineIndex) {
        const line = this.po.purchase_order_lines[lineIndex];
        const quantityReceived = parseFloat(line.quantity_received) + parseFloat(line.newly_received);
        if (line.newly_received > 0) {
          const postData = {};
          postData.line = line;
          postData.line.quantity_received = parseFloat(postData.line.newly_received);
          axios.post(`/purchaseOrderReceipts/receive.json?token=${this.token}`, postData)
            .then((response) => {
              const receipt = response.data.receipt;
              this.po.purchase_order_lines[lineIndex].quantity_received = quantityReceived;
              this.po.purchase_order_lines[lineIndex].purchase_order_receipts.push(receipt);
              this.po.purchase_order_lines[lineIndex].newly_received = null;
            });
        }
      },
      deleteReceived(index, rindex) {
        const receipt = this.po.purchase_order_lines[index].purchase_order_receipts[rindex]
        const receiptId = receipt.id;
        const quantityReceived = (this.po.purchase_order_lines[index].quantity_received - receipt.quantity);
        axios.get(`/purchaseOrderReceipts/delete/${receiptId}.json?token=${this.token}`)
          .then(() => {
            this.po.purchase_order_lines[index].purchase_order_receipts.splice(rindex, 1);
            this.po.purchase_order_lines[index].quantity_received = quantityReceived;
          });
      },
      deleteLine(index) {
        const postData = {};
        postData.line = this.po.purchase_order_lines[index];
        axios.post(`/purchaseOrderLines/remove.json?token=${this.token}`, postData)
          .then(() => {
            this.po.purchase_order_lines.splice(index, 1);
          });
      },
    },
    filters: {
      removeHash(value) {
        return value.substring(value.indexOf("__") + 2);
      },
    },
    mounted() {
      this.getAuthorisers();
      this.getPurchaseOrder();
    },
  }
  </script>